import 'iframe-resizer/js/iframeResizer';

const iframes = iFrameResize({
    checkOrigin: [
        'https://custom-research.de',
        'https://ads.custom-research.de',
        'https://embed.custom-research.de',
        'http://custom-research.de',
        'http://ads.custom-research.de',
        'http://embed.custom-research.de',
        'http://localhost',
        'http://localhost:5000',
        'https://localhost',
        'https://localhost:5000',
    ],
    inPageLinks: true,
    onMessage: ({iframe, message}) => {
        if (message.name === 'cr__afterUpdate') {
            iframe.iFrameResizer.resize();
        }
        if (message.name === 'cr__trackEvent') {
            if (typeof window.__buynowFuncTrackEvent === 'function') {
                window.__buynowFuncTrackEvent({
                    ...message.payload,
                    isCustomResearch: true,
                });
            }
        }
        if (message.name === 'cr__trackProductInView') {
            const event = new CustomEvent('cr__trackProductInView', {
                detail: message.detail,
            });

            window.dispatchEvent(event);
        }
    },
}, '.iframe--survey');

